import { IN_DEBUG, STATUS } from "./glb";

const xjs = {

    /**
     * 
     * @param {*} input 
     * @returns 
     */
    create_xml_object: (input) => {
        
        try{
            
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(input, "text/xml");
            return xmlDoc;

        }catch(e){
            if(IN_DEBUG){
                console.log(e.message);
            }
        }

        return null;
    },

    /**
     * 
     * @param {*} input 
     * @param {*} comparison 
     * @returns 
     */
    xml_to_json: (input, comparison) => {
        
        try{
            
            const now = new Date();

            const xmlDoc = xjs.create_xml_object(input);
            if(xmlDoc){
                
                const composed_json = [];

                const bills = xmlDoc.getElementsByTagName("BillList")[0].children;
                if(bills && bills.length > 0){
                    
                    for(let bill = 0; bill < bills.length; bill++){

                        const current_bill      = bills[bill];
                        const bill_id           = current_bill.getElementsByTagName('CodiceConto')[0].textContent;
                        const table             = current_bill.getElementsByTagName('IDTavolo')[0].textContent;
                        const total             = current_bill.getElementsByTagName('Total')[0].textContent;
                        const detailLines       = current_bill.getElementsByTagName('DetailList')[0].children;
                        const check_ticket      = current_bill.getElementsByTagName('IsTicket') && current_bill.getElementsByTagName('IsTicket').length > 0;
                        const cmd_ticket        = current_bill.getElementsByTagName('NumComandaAsporto') && current_bill.getElementsByTagName('NumComandaAsporto').length > 0 ? current_bill.getElementsByTagName('NumComandaAsporto')[0].textContent : null;
                        const id_ticket         = check_ticket ? current_bill.getElementsByTagName('IsTicket')[0].textContent : null;
                        let is_delivery         = false;
                        let hour                = null;
                        let deliveryType        = null;
                        let deliveryCustomer    = null;
                        const lines             = [];

                        // Correction list 29.05.2024
                        const corrections_list = [];
                        try{
                            let corrections = current_bill.getElementsByTagName('CorrectionsList')[0];
                            if(corrections && corrections.children.length > 0){
                                const billCorrections = corrections.getElementsByTagName('BillCorrection');
                                for(let b = 0; b < billCorrections.length; b++){
                                    
                                    const idPos     = billCorrections[b].getElementsByTagName('IdentificativoPOS')      ? (billCorrections[b].getElementsByTagName('IdentificativoPOS')[0].textContent ?? "-1")                         : "-1";
                                    const newQta    = billCorrections[b].getElementsByTagName('QtaNew')                 ? (billCorrections[b].getElementsByTagName('QtaNew')[0].textContent ?? "1")                                     : "1";
                                    const oldQta    = billCorrections[b].getElementsByTagName('QtaOLD')                 ? (billCorrections[b].getElementsByTagName('QtaOLD')[0].textContent ?? "1")                                     : "1";
                                    const updatedAt = billCorrections[b].getElementsByTagName('DateTimeAfterChange')    ? (billCorrections[b].getElementsByTagName('DateTimeAfterChange')[0].textContent ?? "0001-01-01T00:00:00")      : "0001-01-01T00:00:00";
                                    const waiter    = billCorrections[b].getElementsByTagName('Cameriere')              ? (billCorrections[b].getElementsByTagName('Cameriere')[0].textContent ?? "")                                   : "";

                                    corrections_list.push(
                                        {
                                            new_quantity    : newQta,
                                            old_quantity    : oldQta,
                                            updated_at      : updatedAt,
                                            waiter          : waiter,
                                            id_pos          : idPos
                                        }
                                    );

                                }
                            }
                        }catch(_){}

                        // Per versioni diverse tra rest gateway e video comanda!
                        try{
                            const prepareFor = current_bill.getElementsByTagName('DaPrepararePer')[current_bill.getElementsByTagName('DaPrepararePer').length - 1].textContent;
                            const orderType  = current_bill.getElementsByTagName('TipoOrdine')[current_bill.getElementsByTagName('TipoOrdine').length - 1].textContent;
                            const customer  = current_bill.getElementsByTagName('Name')[current_bill.getElementsByTagName('Name').length - 1].textContent;
                            if(prepareFor !== "0001-01-01T00:00:00" && (orderType == "1" || orderType == "2")){
                                try{
                                    hour = (prepareFor.split("T")[1].toString()).substring(0, 5);
                                }catch(_){}
                                deliveryType        = orderType == "1" ? "Consegna" : "Ritiro";
                                deliveryCustomer    = customer;
                                is_delivery         = true;
                            }
                        }catch(_){}

                        for(let lIndex = 0; lIndex < detailLines.length; lIndex++){
                            
                            const line          = detailLines[lIndex];
                            const note          = line.getElementsByTagName('ArticleNote')[0].textContent;
                            const date_get      = line.getElementsByTagName('DataAcquisizione')[0].textContent;
                            const code_waiter   = line.getElementsByTagName("CodCameriere")[0].textContent;
                            const desc_waiter   = line.getElementsByTagName("Cameriere") && line.getElementsByTagName("Cameriere").length > 0 ? line.getElementsByTagName("Cameriere")[0].textContent : "";
                            const vai_con       = line.getElementsByTagName('CommPreparazione')[0].textContent;
                            const indexQta      = line.getElementsByTagName('Qta').length - 1;
                            const code          = line.getElementsByTagName('Code')[0].textContent;
                            const unique_code   = line.getElementsByTagName('IdentificativoPOS')[0].textContent;
                            const price         = line.getElementsByTagName('Price')[0].textContent;
                            const quantity      = line.getElementsByTagName('Qta')[indexQta].textContent;
                            const description   = line.getElementsByTagName('Name')[line.getElementsByTagName('Name').length - 1].textContent;
                            const evasione_vai  = line.getElementsByTagName('DataEvasione') && line.getElementsByTagName('DataEvasione').length > 0 ? line.getElementsByTagName('DataEvasione')[line.getElementsByTagName('DataEvasione').length - 1].textContent : '0001-01-01T00:00:00';
                            let nsessione       = null;
                            let contosepid      = null;
                            let menuCode        = null;
                            let menuParent      = null;
                            
                            // Calcola, laddove il vai è presente, il tempo trascorso
                            let elapsed_from_vai_minutes = null;
                            if(evasione_vai && evasione_vai !== "0001-01-01T00:00:00"){
                                const timestamp = new Date(evasione_vai);
                                const elapsed_from_vai = (now - timestamp) ?? 0;
                                elapsed_from_vai_minutes = Math.floor( ( (elapsed_from_vai < 0 ? 0 : elapsed_from_vai) / 1000) / 60 );
                            }

                            try{
                                menuCode = line.getElementsByTagName('MenuIDMaster')[line.getElementsByTagName('MenuIDMaster').length - 1].textContent;
                            }catch(_){}

                            try{
                                menuParent = line.getElementsByTagName('MenuIDRefMaster')[line.getElementsByTagName('MenuIDRefMaster').length - 1].textContent;
                            }catch(_){}

                            try{
                                nsessione = line.getElementsByTagName('NSessioneComanda')[line.getElementsByTagName('NSessioneComanda').length - 1].textContent;
                            }catch(_){}

                            try{
                                contosepid = line.getElementsByTagName('ContoSepID')[line.getElementsByTagName('ContoSepID').length - 1].textContent;
                            }catch(_){}

                            const f_in_comp     = comparison.filter(item => item.unique_code == unique_code);
                            let elapsed_minutes = 0;
                            let elapsed         = 0;

                            if(f_in_comp && f_in_comp.length > 0){
                                // IOS: const timestamp = new Date((f_in_comp[0].update_at.toString().split(" ").join("T") + "Z").trim());
                                try{
                                    const formattedCorrectDate = (f_in_comp[0].update_at).trim().split(".")[0].split(" ").join("T");
                                    const timestamp = new Date(formattedCorrectDate);
                                    elapsed   = (now - timestamp) ?? 0;
                                    elapsed_minutes = Math.floor( ( (elapsed < 0 ? 0 : elapsed) / 1000) / 60 );
                                }catch(_){
                                    const timestamp = new Date((f_in_comp[0].update_at).trim());
                                    elapsed   = (now - timestamp) ?? 0;
                                    elapsed_minutes = Math.floor( ( (elapsed < 0 ? 0 : elapsed) / 1000) / 60 );
                                }
                            }

                            //const filter_status = (STATUS ?? []).filter(item => item.is_start_view == 1);
                            let corrections_ = [];
                            try{
                                corrections_ = corrections_list.filter(c => c.id_pos == unique_code);
                            }catch(_){}

                            lines.push(
                                {
                                    id                   : unique_code,
                                    code                 : code,
                                    price                : price,
                                    quantity             : quantity,
                                    description          : description,
                                    status               : null,
                                    bill_id              : bill_id,
                                    elapsed_minutes      : elapsed_minutes,
                                    spring_id            : null,
                                    extracted            : f_in_comp.length > 0,
                                    original_elapsed     : elapsed,
                                    note                 : note,
                                    date_get             : date_get,
                                    vai_con              : vai_con,
                                    code_waiter          : code_waiter,
                                    desc_waiter          : desc_waiter,
                                    evasione_vai         : (evasione_vai ?? "") !== "0001-01-01T00:00:00" ? true : false,
                                    nsessione            : nsessione,
                                    desc_evasione        : evasione_vai,
                                    contosepid           : contosepid,
                                    elapsed_min_from_vai : elapsed_from_vai_minutes,
                                    id_ticket            : id_ticket,
                                    cmd_ticket           : cmd_ticket,
                                    corrections          : corrections_ ?? [],
                                    menu_code            : menuCode,
                                    menu_parent          : menuParent
                                }
                            );

                        }

                        composed_json.push(
                            {
                                id                  : table,
                                total               : total,
                                lines               : lines ,
                                is_delivery         : is_delivery,
                                hour_delivery       : hour,
                                delivery_type       : deliveryType,
                                delivery_customer   : deliveryCustomer,
                                bill_id             : (lines ?? []).length > 0 ? lines[0].bill_id : null,
                                id_ticket           : id_ticket,
                                cmd_ticket          : cmd_ticket
                            }
                        );

                    }

                }

                return composed_json;
            }

        }catch(e){
            if(IN_DEBUG){
                console.log(e.message);
            }
        }

        return null;
    }

};

export {
    xjs
}