import { AUDIO, B_AUTH, CARRY, COVERS, DEVS, DEV_ID, DEV_PRDS, DYLOG_URL, EXT_CODES, groupBy, inSameCommand, IN_DEBUG, IN_MODIF, MIDD_URL, N_OF_DISH, PLAY_AUDIO, PRDS, PW, QTA_MNG, RC, SC, set_ba, set_n_of_dish, set_stat_nov, STATUS, STAT_NOV, WKP_NOV } from "./glb";
import { Base64 } from 'js-base64';
import { xjs } from "./xjs";
import websocket from "./ws";
import { v4 as uuidv4 } from "uuid";

const bcs = {

    /**
     * 
     */
    init: () => {
        try{
            const enabled_web_socket = localStorage.getItem('enabled_websocket');
            if(enabled_web_socket == "1"){
                websocket();
            }
        }catch(_){}
        bcs.main_f();
    },

    /**
     * 
     */
    stack_call: () => {
        bcs.main_f();
    },

    /**
     * 
     * @param {*} call_stack 
     */
    main_f: async (call_stack = true, end_process = null) => {

        if(call_stack){
            const hourglass = document.querySelector('#hourglass_div');
            if(hourglass){
                hourglass.classList.add('animate');
            }
        }


        try{
            //if(IN_MODIF){
            //    if(IN_DEBUG){
            //        console.log("In modif, waiting for cleaning..");
            //    }
            //    return;
            //}

            if(!B_AUTH){
                //const _ = `${Base64.encode(SC)}:${Base64.encode(PW)}`;
                set_ba(`${Base64.encode(SC)}:${Base64.encode(PW)}`);
            }

            let use_notify_system = false;
            try{
                const enabled_websocket = localStorage.getItem('enabled_websocket');
                if(enabled_websocket == "1"){
                    use_notify_system = true;
                }
            }catch(_){}
            let use_ticket_list = false;
            try{
                const enabled_ticket_list = localStorage.getItem('enabled_ticket_list');
                if(enabled_ticket_list == "1"){
                    use_ticket_list = true;
                }
            }catch(_){}

            let idApp = "";
            try{
                let unique_id_device = localStorage.getItem('uuid_app');
                if(!unique_id_device){
                    unique_id_device = uuidv4();
                    localStorage.setItem('uuid_app', unique_id_device)
                }
                idApp = `${RC}_${unique_id_device}`;
            }catch(_){}

            const today = new Date();
            const formatted_today = `${today.getFullYear().toString()}-${(today.getMonth() + 1).toString().padStart(2,'0')}-${today.getDate().toString().padStart(2,'0')}`

            const b_http_res = 
                await fetch(
                    MIDD_URL + "/api/v1/bcv/get_bill" + (use_notify_system ? "?useNotifySystem=1" : "") + (use_ticket_list ? `${use_notify_system ? '' : '?'}&processTicketList=1` : ''),
                    {
                        method: "POST",
                        headers: { 'Content-Type' : 'application/json' },
                        body: JSON.stringify({
                            url             : DYLOG_URL,
                            auth            : B_AUTH,
                            restaurant_code : RC,
                            subscriber_code : SC,
                            xml             : 
                                '<?xml version="1.0" encoding="UTF-8"?>                                                             ' + 
                                '<InfoBill xmlns:i="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://dylog.it/RestGateway"> ' + 
                                '   <IdentificativoAPP>' + idApp + '</IdentificativoAPP>                                            ' + 
                                '   <RestaurantCode>'+RC+'</RestaurantCode>                                                         ' + 
                                '   <Subscriber>'+SC+'</Subscriber>                                                                 ' + 
                                '   <TipoConto>0</TipoConto>                                                                        ' + 
                                '</InfoBill>                                                                                        '
                        })
                    }
                );
            
            if(b_http_res.status == 200){
                const json_input = await b_http_res.json();
                if(json_input.Result == "OK"){
                    
                    const xml_input         = json_input.Xml;
                    const comparison        = json_input.Comparison;
                    const work_in_progress  = json_input.WorkInProgress;

                    let txns = [];
                    if(xml_input){
                        
                        txns = (xjs.xml_to_json(xml_input, comparison ?? []) ?? []);
                        //const extracted_position_element = [];
                        (comparison ?? []).forEach((c) => {
                            
                            for(let t = 0; t < txns.length; t++){
                                
                                let l_filtered = txns[t].lines.filter(l => l.id.toString() == c.unique_code);

                                if(l_filtered.length > 0){
                                    l_filtered[0].status                    = c.status;
                                    l_filtered[0].spring_id                 = c.id;
                                    l_filtered[0].timestamp                 = c.update_at;
                                    l_filtered[0].time_in_to_do             = c.time_in_to_do;
                                    l_filtered[0].menu_composition          = c.menu_composition;
                                    l_filtered[0].variations                = c.variations;
                                    l_filtered[0].done                      = c.done ?? 0;
                                    l_filtered[0].moved_in_new_status_at    = c.moved_in_new_status_at;
                                    break;
                                }
                            }

                        });

                    }

                    // Calcolo numero dei coperti per tavolo
                    // Calcolo effettuato all'inizio poichè dopo
                    // avviene il controllo dei prodotti da escludere dalla vista!
                    txns.forEach(txn => {

                        let number_of_covers = 0;
                        txn.lines.forEach(line => {
                            if(line.code){
                                const idDish = parseInt(line.code);
                                if(idDish){
                                    if(COVERS.includes(idDish)){
                                        number_of_covers += parseInt( line.quantity );
                                    }
                                }
                            }
                        });

                        txn.number_of_covers = number_of_covers ?? 0;

                    });

                    const filtered_txns = [];
                    (txns ?? []).forEach((txn) => {

                        const filtered_dishes = [];
                        txn.lines.forEach((line) => {
                            if(line.code){
                                if( (PRDS ?? []).includes( parseInt(line.code) ) ){
                                    filtered_dishes.push(line);
                                }
                            }
                        });

                        // Controlla i riporti in funzione del reparto in cui ti trovi
                        if(localStorage.getItem('monitor') == "command" || localStorage.getItem('monitor') == "table"){
                            //let is_first_carry = true;
                            txn.lines.forEach((line) => {
                                if(line.code){
                                    const _carry = CARRY.filter(c => c.id_device == DEV_ID && c.id_product == parseInt(line.code) && DEV_ID !== c.id_carry_device);
                                    if(_carry && _carry.length > 0){
                                        _carry.forEach(__carry => {
                                            const copy = JSON.parse(JSON.stringify(line));
                                            const _index = DEVS.findIndex(d => d.id == __carry.id_carry_device);
                                            if(_index > -1){
                                                copy.is_carry = true;
                                                copy.carry_device = DEVS[_index].code;
                                                //copy.is_first_carry = is_first_carry;
                                                filtered_dishes.push(copy);
                                                //if(is_first_carry){
                                                //    is_first_carry = false;
                                                //}
                                            }
                                        });
                                    }
                                }
                            });
                        }

                        if(filtered_dishes.length > 0){
                            txn.lines = [...JSON.parse(JSON.stringify(filtered_dishes))];
                            filtered_txns.push(txn);
                        }

                    });

                    filtered_txns.forEach((item) => {
                        item.lines.forEach((line) => {
                            const index = work_in_progress.findIndex(wp => wp.bill_id.toString() == line.id.toString());
                            line.work_in_progress = index > -1;
                            if(!line.work_in_progress && WKP_NOV.includes(line.id.toString())){
                                line.work_in_progress = true;
                            }
                            if(line.work_in_progress && WKP_NOV.includes(line.id.toString())){
                                const indexWk = WKP_NOV.findIndex(item => item == line.id.toString());
                                if(indexWk > -1){
                                    const copy = [...WKP_NOV];
                                    copy.splice(indexWk, 1);
                                }
                            }
                        });
                    }); 

                    filtered_txns.forEach((txn) => {
                        txn.lines.forEach((line) => {
                            const filtered_ext_codes = EXT_CODES.filter(item => item.id.toString() == line.code.toString());
                            if(filtered_ext_codes.length > 0){
                                line.ext_code = filtered_ext_codes[0].code;
                            }else{
                                line.ext_code = "";
                            }
                        });
                    });

                    // Stati locali..
                    const new_stat_nov = [];
                    const copy_stat_nov = [...STAT_NOV];
                    (copy_stat_nov ?? []).forEach((item) => {
                        filtered_txns.forEach((txn) => {
                            const filtered = txn.lines.filter(l => l.id.toString() == item.id.toString());
                            if(filtered && filtered.length > 0){
                                if(filtered[0].status !== item.status){
                                    filtered[0].status = item.status;
                                    new_stat_nov.push(item);
                                }
                            }
                        });
                    });

                    // Gestione quantità lavorate
                    filtered_txns.forEach((txn) => {
                        txn.lines.forEach((line) => {
                            const index = QTA_MNG.findIndex(q => q.id == line.id);
                            if(index == -1){
                                QTA_MNG.push({id: line.id, quantity: line.done});
                            }else{
                                if(line.done != QTA_MNG[index].quantity){
                                    QTA_MNG[index].quantity = line.done;
                                }else{
                                    line.done = QTA_MNG[index].quantity;
                                }
                            }
                        });
                    })

                    // Calcola minutaggio per tavolo
                    filtered_txns.forEach((item) => {
                        let waiting_time = 0;
                        //item.lines.forEach((dish) => {
                        //    waiting_time += (dish.elapsed_minutes ?? 0);
                        //});
                        if(item.lines && item.lines.length > 0){
                            waiting_time = ( [...item.lines].sort((a, b) => a.elapsed_minutes - b.elapsed_minutes))[0].elapsed_minutes;
                        }
                        item.waiting_time = waiting_time;
                    });

                    let index_s = 0;
                    filtered_txns.forEach((i) => {
                        i.waiting_time = i.waiting_time + (0.000001 * index_s);
                        index_s++;
                    });

                    set_stat_nov(new_stat_nov);
                    txns = [...filtered_txns];

                    // Ristrutturazione per raggruppamento comanda!
                    if(localStorage.getItem('monitor') == 'command' && localStorage.getItem('group_command_for_minute') == "1"){
                        txns.forEach(tx => {
                            for(let l = 0; l < tx.lines.length; l++){
                                const date_get = tx.lines[l].date_get;
                                for(let ll = l + 1; ll < tx.lines.length; ll++){
                                    const _date_get = tx.lines[ll].date_get;
                                    if(date_get !== _date_get){
                                        if(inSameCommand(date_get, _date_get)){
                                            tx.lines[ll].date_get = date_get;
                                        }
                                    }
                                }
                            }
                        });
                    }

                    // Gestione delivery
                    if(localStorage.getItem('delivery_take_away') == "1" && parseInt(localStorage.getItem('delivery_hour_landing'))){
                        txns.forEach(txn => {

                            const d1    = new Date("1900-01-01T" + txn.hour_delivery);
                            const _d2   = new Date();
                            const d2    = new Date(
                                "1900-01-01T" + 
                                _d2.getHours().toString().padStart(2, '0')      + 
                                ":"                                             + 
                                _d2.getMinutes().toString().padStart(2, '0')    + 
                                ":"                                             + 
                                _d2.getSeconds().toString().padStart(2, '0')
                            );

                            if(d2 < d1){
                                
                                const elapsed = d1 - d2;
                                const elapsed_minutes = Math.floor( ( (elapsed < 0 ? 0 : elapsed) / 1000) / 60 );
                                const hour_diff = elapsed_minutes / 60;

                                const hour_landing = parseInt(localStorage.getItem('delivery_hour_landing'));

                                if(hour_landing){
                                    if(hour_landing <= hour_diff){
                                        txn.display = -1;
                                    }
                                }

                            }

                        });
                    }

                    // Secondo timer..
                    if(localStorage.getItem('use_two_timer') == "1"){
                        txns.forEach(i => {

                            // Controlla se ha almeno un vai
                            const hasOneVaiCon = i.lines.findIndex(v => v.vai_con) > -1;
                            if(hasOneVaiCon){
                                i.command_timer = true;
                                i.second_waiting_time = 0;
                            }

                        });
                    }

                    // quali colorare di rosso e quali no
                    for(let txn of txns){
                        const maxTime = parseFloat(localStorage.getItem('max_minutes_for_command') ?? "99999");
                        if(txn.waiting_time >= maxTime){
                            txn.critical_status = true;
                        }
                    }

                    // Se attiva spunta di raggruppamento, prenderà tutti gli articoli
                    // con lo stesso codice e lo stesso stato e li mette insieme
                    try{
                        if(localStorage.getItem('group_by_code') == "1"){
                            for(let t = 0; t < txns.length; t++){
                                const lines = txns[t].lines;
                                let grouped = [];
                                const newLines = [];
                                let hasMenu = false;
                                for(let l = 0; l < lines.length; l++){
                                    const line = lines[l];
                                    const id = line.id;
                                    const code = line.code;
                                    const status = line.status;
                                    const nsessione = line.nsessione;
                                    const vai_con = line.vai_con;
                                    if(line.variations){
                                        newLines.push(line);
                                        continue;
                                    }
                                    if(line.menu_code || line.menu_parent){
                                        hasMenu = true;
                                        continue;
                                    }
                                    const index = grouped.findIndex(g => g.code == code && g.status == status && g.nsessione == nsessione && g.vai_con == vai_con); //  && g.nsessione == nsessione
                                    if(index == -1){
                                        grouped.push({code: code, status: status, nsessione: nsessione, vai_con: vai_con});
                                        let quantity = parseInt(line.quantity);
                                        const ids = [];
                                        const related = [];
                                        const filteredLines = lines.filter(l => (l.id != line.id) && (l.code == code) && !l.variations && (l.status == status) && (l.nsessione == nsessione) && (l.vai_con == vai_con) && !l.is_carry); //  && l.nsessione == nsessione && !l.is_carry
                                        filteredLines.forEach(ll => {
                                            ids.push(ll.id);
                                            related.push(ll);
                                            const qta = parseInt(ll.quantity);
                                            quantity += qta;
                                        });
                                        line.quantity = quantity.toString();
                                        line.ids_join = ids;
                                        line.relateds = related;
                                        newLines.push(line);
                                    }
                                }
                                if(hasMenu){
                                    
                                    // Scrivi i menu
                                    const menus = lines.filter(l => l.menu_code);
                                    const menusItems = lines.filter(l => l.menu_parent);

                                    menus.forEach(m => {
                                        newLines.push(m);
                                        const subitems = menusItems.filter((mm) => mm.menu_parent == m.menu_code); 
                                        subitems.forEach((s) => {
                                            const findIndex = newLines.findIndex(n => n.id && s.id && (n.id == s.id));
                                            if(findIndex == -1){
                                                newLines.push(s);
                                            }
                                        });
                                    });

                                }
                                txns[t].lines = newLines;
                            }
                        }
                    }catch(_){
                        console.log(_);
                    }

                    // Fix subarticolo con riporto
                    try{ // Rimuovi dai carry quelli già presenti sopra ma come non carry
                        txns.forEach(txn => {
                            const idsToRemove = [];
                            txn.lines.filter(t => t.is_carry).forEach(t => {
                                const id = t.id;
                                const index = txn.lines.findIndex(tt => tt.id == id && !tt.is_carry);
                                if(index > -1){
                                    idsToRemove.push(id);
                                }
                            }); 
                            idsToRemove.forEach(i => {
                                const index = txn.lines.findIndex(t => t.id == i && t.is_carry);
                                if(index > -1){
                                    txn.lines.splice(index, 1);
                                }
                            });
                        });
                    }catch(e){
                        console.log(e);
                    }

                    const bc_event = new CustomEvent(
                        'bc:event', 
                        {
                            detail: txns
                        }
                    );
            
                    if(end_process){
                        end_process();
                    }

                    //if(!IN_MODIF)
                    dispatchEvent(bc_event); // Fai il dispatch degli eventi!

                    try{
                        let array_size = 0;
                        txns.forEach((t) => {
                            array_size += t.lines.length;
                        });
                        if(array_size > N_OF_DISH){
                            if(IN_DEBUG){
                                console.log("Sound!");
                            }
                            if(PLAY_AUDIO){
                                document.querySelector('#audio_bell').play();
                            }
                        }
                        set_n_of_dish(array_size);
                    }catch(_){}

                }
            }
        }catch(e){

            if(IN_DEBUG){
                console.log("Catch in service", e);
            }

        } finally {
            
            if(!call_stack) return;

            setTimeout(() => {
                if(call_stack){
                    const hourglass = document.querySelector('#hourglass_div');
                    if(hourglass){
                        hourglass.classList.remove('animate');
                    }
                }
            }, 3000);

            //if(!IN_MODIF){
            setTimeout(() => {
                bcs.stack_call();
            }, 30e3);
            //}

        }
    },

    json_wrapper: (input) => {

    }

};

export default bcs;