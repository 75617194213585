import { useEffect, useState } from "react";

export default function LDish({dishes, filter_for, filter_id, status, click_event, remove_filter}){

    const [wrap_dish, set_wrap_dish]        = useState([]);
    const [vaiConCount, setVaiContCount]    = useState([]);

    const wrap_event = (status) => {
        const vaiConCount_ = [];
        let _dishes = [];
        if(status){
            (dishes ?? []).filter(item => item.status == status.replace("view_", "") && item.ext_code && !item.is_carry).forEach((item) => {
                const index = (_dishes.findIndex(d => d.ext_code.toString() == item.ext_code.toString()));
                if ( index == -1 ){
                    item.global_quantity = parseInt(item.quantity);
                    _dishes.push(item);
                } else {
                    _dishes[index].global_quantity += parseInt(item.quantity);
                }
            });
        }

        // Ordina..
        const numeric = [];
        const alphanumeric = [];

        _dishes.forEach((item) => {
            if(isNaN(item.ext_code)){
                alphanumeric.push(item);
            }else{
                numeric.push(item);
            }
        });

        numeric.sort((a, b) => parseInt(a.ext_code) - parseInt(b.ext_code));
        alphanumeric.sort((a, b) => a.ext_code - b.ext_code);
        
        _dishes = [...numeric, ...alphanumeric];

        dishes.filter(d => d.status == status && d.vai_con).forEach(d => {
            const index = vaiConCount_.findIndex(v => v.ext_code == d.ext_code);
            if(index == -1){
                vaiConCount_.push(
                    {
                        ext_code: d.ext_code,
                        quantity: parseInt(d.quantity)
                    }
                );
            }else{
                vaiConCount_[index]['quantity'] = vaiConCount_[index]['quantity'] + parseInt( d.quantity );
            }
        });
        setVaiContCount(vaiConCount_);

        set_wrap_dish(_dishes);
    };

    const countVaiCon = (extCode) => {
        const index = vaiConCount.findIndex(v => v.ext_code == extCode && v.quantity > 0);
        if(index > -1){
            return "(" + vaiConCount[index]['quantity'] + ")";
        }
        return "";
    };

    useEffect(() => wrap_event(status), [dishes]);
    useEffect(() => wrap_event(status), [status]);

    return (
        <>
            {
                wrap_dish && wrap_dish.length > 0 ? 
                    wrap_dish.map((item, index) => 
                        <div class={`side-element ${ (filter_for == 'dish' && filter_id == item.ext_code ? 'active' : '')}`} key={`dish_${index}`} onClick={() => {
                            if(filter_for == 'dish' && filter_id == item.ext_code){
                                remove_filter();
                                return;
                            }
                         click_event(item.ext_code);
                        }}>
                            {localStorage.getItem('sidebar_extended') == "1" ? item.description : item.ext_code} ({item.global_quantity ?? ""}) 
                            <span style={{color: (filter_for == 'dish' && filter_id == item.ext_code) ? "red" : "white", fontWeight: "bold", display: localStorage.getItem('count_dish_with_exit') == "1" ? "block" : "none"}}>{countVaiCon(item.ext_code)}</span>
                        </div>
                    )
                :
                    <></>
            }
        </>
    );
}