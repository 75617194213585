import { useEffect, useState } from "react";
import bcs from "./bcs";
import { ACT_LIST, AUDIO, DEV_ID, INIT_ALL, IN_DEBUG, MIDD_URL, RC, set_act_list, set_audio, set_covers, set_devs, set_dev_id, set_dev_prds, set_du, set_ext_codes, set_glb_carry, set_init_all, set_prds, set_pw, set_rc, set_sc, set_status, STATUS, VERSION } from "./glb";
import { add_status, check_lic, get_carry, get_devices, get_devices_products, get_time_to_cancel, save_device } from "./reuse";
import View2 from "./v2";
import { v4 as uuidv4 } from 'uuid';

const bcListener = (e, setTxns) => {
    if(e.detail){
        setTxns(e.detail);
    }
};

const View1 = () => {

    const [rInit, setRInit]     = useState(true);
    const [rcInput, setRcInput] = useState(null);
    const [scInput, setScInput] = useState(null);
    const [pwInput, setPwInput] = useState(null);
    const [txns, setTxns]       = useState([]);

    useEffect(() => {

        // Id App
        try{
            let unique_id_device = localStorage.getItem('uuid_app');
            if(!unique_id_device){
                unique_id_device = uuidv4();
                localStorage.setItem('uuid_app', unique_id_device)
            }
        }catch(_){}

        if(!localStorage.getItem('max_minutes_for_command')){
            localStorage.setItem('max_minutes_for_command', '999999');
        }

        if(!localStorage.getItem('line_font_size')){
            localStorage.setItem('line_font_size', '16');
        }

        if(!localStorage.getItem('group_command_for_minute')){
            localStorage.setItem('group_command_for_minute', '1');
        }

        if(!localStorage.getItem('subscriber_sync_product')){
            localStorage.setItem('subscriber_sync_product', 'DylogApp');
        }

        if(!localStorage.getItem('delivery_hour_landing')){
            localStorage.setItem('delivery_hour_landing', '1');
        }

        if(!localStorage.getItem('use_two_timer')){
            localStorage.setItem('use_two_timer', '0');
        }

        if(!localStorage.getItem('display_vai_filter')){
            localStorage.setItem('display_vai_filter', '0');
        }

        if(!localStorage.getItem('external_code')){
            localStorage.setItem('external_code', '1');
        }

        if(!localStorage.getItem('next_command')){
            localStorage.setItem('next_command', '0');
        }

        if(!localStorage.getItem('enabled_ticket_list')){
            localStorage.setItem('enabled_ticket_list', '0');
        }

        if(!localStorage.getItem('alert_screen')){
            localStorage.setItem('alert_screen', '0');
        }

        if(!localStorage.getItem('scroll_screen')){
            localStorage.setItem('scroll_screen', '0');
        }

        if(!localStorage.getItem('alert_minute_product_in_command')){
            localStorage.setItem('alert_minute_product_in_command', '0');
        }

        if(!localStorage.getItem('alert_minute_product_in_command_time')){
            localStorage.setItem('alert_minute_product_in_command_time', '5');
        }

        if(!localStorage.getItem('limit_command_for_view')){
            localStorage.setItem('limit_command_for_view', '0');
        }

        if(!localStorage.getItem('command_for_view')){
            localStorage.setItem('command_for_view', '10');
        }

        if(!localStorage.getItem('show_alert_timer')){
            localStorage.setItem('show_alert_timer', '0');
        }

        if(!localStorage.getItem('critical_command')){
            localStorage.setItem('critical_command', '[]');
        }
        
        if(!localStorage.getItem('active_critical_command')){
            localStorage.setItem('active_critical_command', '0');
        }

        if(!localStorage.getItem('use_department_chat')){
            localStorage.setItem('use_department_chat', '0');
        }

        if(!localStorage.getItem('order_as_come')){
            localStorage.setItem('order_as_come', '0');
        }

        if(!localStorage.getItem('group_by_code')){
            localStorage.setItem('group_by_code', '0');
        }

        if(!localStorage.getItem('explode_menu')){
            localStorage.setItem('explode_menu', '0');
        }

        if(!localStorage.getItem('order_in_command_by_external_code')){
            localStorage.setItem('order_in_command_by_external_code', '0');
        }

        if(!localStorage.getItem('count_dish_with_exit')){
            localStorage.setItem('count_dish_with_exit', '0');
        }

        let color = null;
        if(!(color = localStorage.getItem('override_color'))){
            localStorage.setItem('override_color', '#008833');
        }else{
            if(color != "#008833"){
                document.documentElement.style.setProperty('--green', color);
                document.documentElement.style.setProperty('--bs-primary', color);
                document.documentElement.style.setProperty('--shadow-sidebar', color);
                document.documentElement.style.setProperty('--border', color);
            }
        }

        if(!INIT_ALL){

            // All exc
            window.addEventListener('unhandledrejection', (e) => {
                if(IN_DEBUG){
                    console.log("Error unhandledrejection", e.reason.message);
                    try{
                        fetch(MIDD_URL + `/api/v1/bcv/log/?restaurant_code=${RC}&message=${e.reason.message}`);
                    }catch(_){}
                }
            });

            const _rc = localStorage.getItem('bc_rc');
            const _sc = "DylogAPP";                                 //"DylogAPP"; //DylogVIDEOCOMANDE
            const _pw = "781445f8-658f-41ef-180a-55a84a345v2a";     //"781445f8-658f-41ef-180a-55a84a345v2a"; 553147g9-128e-25ej-911b-66a21a355v5k

            if( !_rc ) { return; }

            set_rc(_rc);
            set_sc(_sc);
            set_pw(_pw);
            set_du();

            let order_table_by = localStorage.getItem('order_table_by');
            if(!order_table_by){
                localStorage.setItem('order_table_by', 'time');
            }

            let monitor = localStorage.getItem('monitor');
            if(!monitor){
                localStorage.setItem('monitor', 'table');
            }

            const card_fr = localStorage.getItem('card_fr');
            let var_card_fr = card_fr;
            if(!var_card_fr){
                var_card_fr = '1fr 1fr 1fr 1fr 1fr 1fr';
                localStorage.setItem('card_fr', 6);
            }else{
                var_card_fr = "";
                for(let i = 0; i < parseInt(card_fr); i++){
                    var_card_fr += '1fr ';
                }
            }

            let percentage = "";
            switch(card_fr){

                case "1": {
                    percentage = "100%";
                } break;

                case "2": {
                    percentage = "49%";
                } break;

                case "3": {
                    percentage = "32%";
                } break;

                case "4": {
                    percentage = "24%";
                } break;

                case "5": {
                    percentage = "19%";
                } break;

                case "6": {
                    percentage = "15%";
                } break;

                case "7": {
                    percentage = "13%";
                } break;

                case "8": {
                    percentage = "11%";
                } break;

                case "9": {
                    percentage = "10%";
                } break;

                case "10": {
                    percentage = "9%";
                } break;

                case "11": {
                    percentage = "8%";
                } break;

            }

            if(!percentage){
                percentage = "15%";
            }

            document.documentElement.style.setProperty('--grid-layout', `repeat(auto-fill, minmax(${percentage}, 1fr))`);

            if(localStorage.getItem('monitor') !== 'table' && localStorage.getItem('monitor') !== 'command'){
                if(localStorage.getItem('grid_layout_dish')){
                    document.documentElement.style.setProperty('--grid-layout', localStorage.getItem('grid_layout_dish'));
                }
            }else{
                if(localStorage.getItem('grid_layout_table_command')){
                    document.documentElement.style.setProperty('--grid-layout', localStorage.getItem('grid_layout_table_command'));
                }
            }

            if(!localStorage.getItem('grid_layout_value_dish')){
                localStorage.setItem('grid_layout_value_dish', '50');
            }

            if(!localStorage.getItem('grid_layout_value_table_command')){
                localStorage.setItem('grid_layout_value_table_command', '50');
            }

            (async() => {
                
                // Controlla licenza..
                if(!await check_lic(_rc)) return;

                try{ // Sviluppo 19.02.2023, ottenimento coperti
                    const res_covers = await fetch(`${MIDD_URL}/api/v1/bcv/get_covers?restaurant_code=${_rc}&subscriber_code=${_sc}`);
                    if(res_covers &&  res_covers.status == 200){
                        const json = await res_covers.json();
                        if(json.Result == "OK" && json.Covers){
                            const cvs = [];
                            (json.Covers ?? []).forEach((cover) => {
                                try{
                                    cvs.push(parseInt(cover.id_product.toString()));
                                }catch(_){}
                            });
                            set_covers(cvs ?? []);
                        }
                    }
                }catch(_){}

                const res_status = 
                    await fetch(
                        `${MIDD_URL}/api/v1/bcv/get_status?restaurant_code=${_rc}&subscriber_code=${_sc}`
                    );

                let in_err_after_status_check = true;
                if(res_status.status == 200){

                    try{
                        const json_res_status = await res_status.json();
                        if(json_res_status.Result == "OK"){

                            if(json_res_status.Status.length == 0){
                                await add_status(null, 'DA FARE', 0, "fatto", 1);
                                await add_status(null, 'FATTO', 1, "fatto", 0);
                                window.location.reload();
                            }

                            const devices = await get_devices();
                            if(devices && devices.status == 200){
                                const json_devices = await devices.json();
                                if(json_devices.Result == "OK"){
                                    
                                    let all_devices = [...(json_devices.Devices ?? [])];
                                    set_devs(all_devices);
                                    if(all_devices.length == 0){

                                        await save_device("TAB1");
                                        await save_device("TAB2");
                                        localStorage.removeItem('device');
                                        window.location.reload();

                                    }
                                    const my_device = localStorage.getItem('device');
                                    if(!my_device){
                                        const dev_id = all_devices[0].id;
                                        set_dev_id(parseInt(dev_id));
                                        localStorage.setItem('device', dev_id.toString());
                                    }else{
                                        const filtered_devs = all_devices.filter(item => item.id.toString() == my_device);
                                        if(filtered_devs.length == 0){
                                            localStorage.setItem('device', all_devices[0].id.toString());
                                            set_dev_id(all_devices[0].id);
                                        }else{
                                            set_dev_id(parseInt(my_device));
                                        }
                                    }

                                }
                            }

                            const res_devices_products = await get_devices_products();
                            if(res_devices_products.status == 200){
                                const json_devices_products = await res_devices_products.json();
                                if(json_devices_products.Result == "OK"){
                                    
                                    const prds_to_filter = [];
                                    const dev_prds       = [];
                                    const ext_codes      = [];

                                    (json_devices_products.Devices ?? []).forEach((item) => {
                                        if(item.ext_code){
                                            ext_codes.push({id: item.id_product, code: item.ext_code});
                                        }
                                        dev_prds.push({
                                            id_product: item.id_product,
                                            id_device: item.id_device
                                        });
                                        if( parseInt(item.id_device) == parseInt(DEV_ID) ){
                                            prds_to_filter.push(item.id_product);
                                        }
                                    });

                                    set_dev_prds(dev_prds);
                                    set_prds(prds_to_filter);
                                    set_ext_codes(ext_codes);

                                }
                            }

                            const carry = await get_carry();
                            set_glb_carry(carry ?? []);

                            set_status( json_res_status.Status );
                            in_err_after_status_check = false;
                            set_act_list(true);
                            window.addEventListener('bc:event', (e) => bcListener(e, setTxns));

                            setRInit(false);
                            bcs.init();
                            set_init_all(true);
                            
                        }
                    }catch(e){
                        if(IN_DEBUG){
                            console.log(e);
                        }
                    }

                }

                // Richiedi tempistiche di cancellazione comanda
                try{

                    await get_time_to_cancel();

                }catch(e){
                    console.log(e);
                }

                if(in_err_after_status_check){
                    if(IN_DEBUG){
                        console.log("Error after check status");
                    }
                }

            })();
        }else{
            window.location.reload();
        }

        return (() => {
            try{
                window.removeEventListener('bc:event', bcListener);
            }catch(e){
                if(IN_DEBUG){
                    console.log("Error during remove listener bc:event", e);
                }
            }
        });

    }, []);

    return (
        rInit ? 
            <>
                <div class="login-page">
                    <p>Accedi</p>
                    <div class="login-form">
                        <div class="form-floating">
                            <input class="form-control" placeholder="Nome Stato" id="restaurantCode" value={rcInput} onChange={(e) => setRcInput(e.target.value)}></input>
                            <label for="username">Codice Ristorante </label>
                        </div>
                        <button type="button" class="btn btn-primary btn-lg" onClick={ async () => {

                            if(rcInput){
                                if(!await check_lic(rcInput)) return;
                                localStorage.setItem('bc_rc', rcInput.trim());
                                //localStorage.setItem('bc_sc', scInput);
                                //localStorage.setItem('bc_pw', pwInput);
                                window.location.reload();
                            }

                        }}>Accedi</button>
                    </div>  
                </div>
            </>
        :
            <View2 txns={txns ?? []}/>
    );
};

export default View1;